import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { AdditionalQuestionGroup, RespondentFormData } from '../../../../types/sdq';
import React, { ComponentProps } from 'react';

import { QuestionBlock } from '../QuestionBlock';
import { AdditionalQuestionContainer } from './AdditionalQuestionContainer';

interface Props extends ComponentProps<typeof QuestionBlock> {
  additionalQuestionGroup?: AdditionalQuestionGroup;
  setValue: UseFormSetValue<RespondentFormData>;
  currentFormData: RespondentFormData;
  formErrors: FieldErrors<RespondentFormData>;
}

export const AdditionalQuestionGroupContainer = ({
  additionalQuestionGroup,
  currentFormData,
  setValue,
  formErrors,
  ...rest
}: Props) => {
  if (!additionalQuestionGroup) return null;

  return (
    <QuestionBlock className={'flex flex-col gap-8'} {...rest}>
      <QuestionBlock className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-8'}>
        <p className='font-semibold'>追加の質問にお答えください。</p>
      </QuestionBlock>

      {additionalQuestionGroup.additional_question_relations.map((relation, questionIndex) => {
        return (
          <AdditionalQuestionContainer
            key={`additional-question-${relation.id}`}
            questionText={relation.question.question_text}
            questionIndex={questionIndex + 1}
            choices={relation.question.choice_group}
            setValue={setValue}
            nestedFieldKey={`responses.${questionIndex}.choice`}
            radioValue={currentFormData.additional_question_response_group.responses[questionIndex]?.choice}
            formErrors={formErrors}
            className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-4'}
          />
        );
      })}
    </QuestionBlock>
  );
};
