let configs: { [key: string]: string } = {
  API_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '',
  GG_API_KEY: process.env.REACT_APP_GG_API_KEY ? process.env.REACT_APP_GG_API_KEY : '',
  SAVE_SURVEY_URL: 'surveys/',
  GET_CITIES_URL: 'cities/',
  GET_STATISTIC_URL: 'statistic',
  QUESTIONS: 'questions/',
  GET_SCHOOLS_URL: 'schools/',
  GET_AUTH_URL: 'auth/',
  // ASQ
  GET_ASQ_SURVEY_LIST_URL: 'asq/surveys/',
  GET_ASQ_SURVEY_DETAIL_URL: 'asq/surveys/', // need to add survey id at the end
  GET_ASQ_RESPONDENT_CHOICE_URL: 'asq/respondent-choices/',
  POST_ASQ_RESPONDENT_URL: 'asq/respondents/',
  // SDQ
  GET_SDQ_SURVEY_LIST_URL: 'sdq/surveys/',
  GET_SDQ_SURVEY_DETAIL_URL: 'sdq/surveys/', // need to add survey id at the end
  GET_SDQ_RESPONDENT_CHOICE_URL: 'sdq/respondent-choices/',
  POST_SDQ_RESPONDENT_URL: 'sdq/respondents/'
};

if (process.env.NODE_ENV === 'development') {
  console.log('DEBUG: process.env.NODE_ENV', process.env.NODE_ENV);
  configs.GET_DEV_URL = 'dev/';
}

export default configs;
