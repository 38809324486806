import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { DatepickerType, DateValueType } from 'react-tailwindcss-datepicker';
import { DatepickerInputValue } from '../../../types/components/common/datepicker';
import { DatePicker } from '../../Common/Datepicker';
import { useMemo } from 'react';
import { RespondentFormData } from '../../../types/sdq';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  datepickerProps: Omit<DatepickerType, 'value' | 'onChange'>;
  fieldKey: keyof RespondentFormData;
  setValue: UseFormSetValue<RespondentFormData>;
  formErrors: FieldErrors<RespondentFormData>;
  value?: DatepickerInputValue;
}

export const DatepickerField = ({ value, setValue, fieldKey, formErrors, datepickerProps, ...rest }: Props) => {
  /**
   * 日付選択時の処理
   * datepicker-tailwindcssのonChangeはDateValueType型で返ってくる。
   * フォームの値を管理するときにDateValueTypeは型の種類が多すぎて使いにくいので、
   * DatepickerInputValue型に変換してからsetValueしている。
   * @param newDate
   */
  const handleOnChange = (newDate: DateValueType) => {
    let newValue: DatepickerInputValue = {
      startDate: undefined,
      endDate: undefined
    };

    if (typeof newDate?.startDate === 'string') {
      newValue.startDate = new Date(newDate.startDate);
    }
    if (typeof newDate?.endDate === 'string') {
      newValue.endDate = new Date(newDate.endDate);
    }
    setValue(fieldKey, newValue);
  };

  const errorMessage = useMemo(() => {
    if (fieldKey === 'birth_date') {
      const errors = formErrors['birth_date'];
      if (!errors) return '';
      if (errors?.message) return errors.message;
      return errors.startDate?.message || errors.endDate?.message || '';
    }
    const errors = formErrors[fieldKey];
    return errors?.message;
  }, [fieldKey, formErrors]);

  return (
    <DatePicker
      datepickerProps={{
        ...datepickerProps,
        onChange: handleOnChange
      }}
      value={value}
      label={''}
      errorMessage={errorMessage}
      {...rest}
    />
  );
};
