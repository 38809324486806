import RadioGroup from '../../Common/RadioGroup';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { OptionItem } from '../../../types/components/common/select';
import { useMemo } from 'react';
import { RespondentFormData } from '../../../types/sdq';

interface Props {
  setValue: UseFormSetValue<RespondentFormData>;
  value?: string; // 'male' といった文字列も可能性があるので string にしている
  formErrors: FieldErrors<RespondentFormData>;
  fieldKey: keyof RespondentFormData;
  /**
   * nestedFieldKey is required when fieldKey is 'daily_activity_response_group'
   * e.g. 'sections.0.responses.0.choice'
   */
  nestedFieldKey?: string;
  options: OptionItem[];
}

function validateQuestionResponseKey(nestedFieldKey?: string): void {
  if (!nestedFieldKey) throw new Error('nestedFieldKey is required');
  const regex = /^responses\.\d+\.choice$/;
  if (!regex.test(nestedFieldKey)) {
    throw new Error(`Invalid nestedFieldKey: ${nestedFieldKey}`);
  }
}

export const RadioGroupField = ({ setValue, value, formErrors, fieldKey, nestedFieldKey, options }: Props) => {
  const setQuestionResponseValue = (newValue: string) => {
    validateQuestionResponseKey(nestedFieldKey);
    const keys = nestedFieldKey!.split('.').map((v) => Number(v));
    const responseIndex = keys[1];
    const name = `${fieldKey}.responses.${responseIndex}.choice`;
    // @ts-ignore
    setValue(name, Number(newValue));
  };

  const handleOnChange = (newValue: string) => {
    if (fieldKey === 'question_response_group' || fieldKey === 'additional_question_response_group') {
      setQuestionResponseValue(newValue);
      return;
    }
    setValue(fieldKey, newValue);
  };

  const errorMessage = useMemo(() => {
    if (fieldKey === 'question_response_group' || fieldKey === 'additional_question_response_group') {
      validateQuestionResponseKey(nestedFieldKey);
      const keys = nestedFieldKey!.split('.').map((v) => Number(v));
      const responseIndex = keys[1];
      return formErrors[fieldKey]?.responses?.[responseIndex]?.choice?.message;
    }

    return formErrors[fieldKey]?.message;
  }, [formErrors, fieldKey, nestedFieldKey]);

  return <RadioGroup value={value} onChange={handleOnChange} options={options} errorMessage={errorMessage} />;
};
