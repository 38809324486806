import React from 'react';
import Title from 'components/Title';
import { useNavigate } from 'react-router-dom';
import SessionType from 'components/SessionType';
import constants from '../../constants';

const SurveyTypeSelectionPage = () => {
  const navigate = useNavigate();

  const navigateToSurveyPage = (type: string, label: string) => {
    if (type === 'enjoji') {
      navigate('/survey');
    } else if (type === 'asq_survey') {
      navigate('/asq');
    } else if (type === 'sdq_survey') {
      navigate('/sdq');
    } else {
      navigate('/question-groups', { state: { type: type, label: label } });
    }
  };

  return (
    <div className='container mx-auto py-10'>
      <Title title='アンケート調査のオプションをお選びください' />
      <div className='mt-10 md:mt-20 lg:px-2 px-4 w-full flex flex-col justify-center items-center lg:flex-row gap-4 lg:gap-9'>
        {constants.SURVEY_TYPES.map((session) => (
          <SessionType
            IconComponent={session.icon}
            label={session.label}
            onClick={() => navigateToSurveyPage(session.type, session.label)}
            key={session.type}
          />
        ))}
      </div>
    </div>
  );
};

export default SurveyTypeSelectionPage;
