import { InputProps, TextInput } from '../../Common/TextInput';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useMemo } from 'react';
import { undefinedToEmptyString } from '../../../helpers/yup-helpers';
import { RespondentFormData } from '../../../types/sdq';

interface Props extends Omit<InputProps, 'label'> {
  value?: string;
  setValue: UseFormSetValue<RespondentFormData>;
  formErrors: FieldErrors<RespondentFormData>;
  fieldKey: keyof RespondentFormData;
}

export const TextInputField = ({ value, setValue, formErrors, fieldKey, ...rest }: Props) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(fieldKey, event.target.value);
  };

  const errorMessage = useMemo(() => {
    return formErrors[fieldKey]?.message;
  }, [fieldKey, formErrors]);

  return (
    <TextInput
      label=''
      value={undefinedToEmptyString(value)}
      onChange={handleOnChange}
      className='w-full'
      errorMessage={errorMessage}
      {...rest}
    />
  );
};
