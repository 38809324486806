import { ToastContainer } from 'react-toastify';
import React, { StrictMode } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import QuestionnairePage from 'pages/Questionnaire';
import SurveyReportPage from 'pages/SurveyReport';
import SubmitSuccessPage from 'pages/SubmitSuccess';
import SurveyTypeSelectionPage from 'pages/SurveyTypeSelection';
import QuestionGroupsPage from 'pages/QuestionGroups';
import DevPage from './pages/Dev';
import { AsqSurveyPage } from './pages/Asq/Survey';
import { SdqSurveyPage } from './pages/Sdq/Survey';

const routes = [
  { path: '/', element: <SurveyTypeSelectionPage /> },
  { path: '/survey', element: <QuestionnairePage /> },
  { path: '/survey-report', element: <SurveyReportPage /> },
  { path: '/submit-success', element: <SubmitSuccessPage /> },
  { path: '/question-groups', element: <QuestionGroupsPage /> },
  { path: '/asq', element: <AsqSurveyPage /> },
  { path: '/sdq', element: <SdqSurveyPage /> }
];

if (process.env.NODE_ENV === 'development') {
  // 開発用のページを追加
  console.log('DEBUG: process.env.NODE_ENV', process.env.NODE_ENV);
  routes.push({ path: '/dev', element: <DevPage /> });
}

let router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: routes
  }
]);

function App() {
  return (
    <StrictMode>
      <RouterProvider router={router} />
      <ToastContainer />
    </StrictMode>
  );
}

export default App;
