import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSdqRespondentChoices, getSdqSurveyList, postSdqSurvey } from 'helpers/backend-helper';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  AdditionalQuestionResponsePostData,
  RespondentFormData,
  RespondentPostData,
  SdqQuestionResponsePostData,
  Survey,
  SurveyItem,
  RespondentChoice
} from '../../../types/sdq';
import { SelectSdqSurveyField } from '../../../components/Sdq/FormFields/SelectSdqSuerveyField';
import { QuestionBlock } from '../../../components/Sdq/Containers/QuestionBlock';
import { QuestionContainer } from '../../../components/Sdq/Containers/QuestionContainer';
import { Respondent } from '../../../components/Sdq/Containers/Respondent';
import { SdqQuestionGroupContainer } from '../../../components/Sdq/Containers/SdqQuestionGroup';
import { AdditionalQuestionGroupContainer } from '../../../components/Sdq/Containers/AdditionalQuestionGroup';

const DEFAULT_QUESTION_RESPONSE_GROUP_VALUES: {
  responses: SdqQuestionResponsePostData[];
} = {
  responses: []
};

const DEFAULT_ADDITIONAL_QUESTION_RESPONSE_GROUP_VALUES: {
  responses: AdditionalQuestionResponsePostData[];
} = {
  responses: []
};

const DEFAULT_RESPONDENT_VALUES: RespondentFormData = {
  last_name: '',
  first_name: '',
  birth_date: {
    startDate: undefined,
    endDate: undefined
  },
  sex_of_child: '',
  signature: '',
  relationship: '',
  relationship_detail: '',
  question_response_group: DEFAULT_QUESTION_RESPONSE_GROUP_VALUES,
  additional_question_response_group: DEFAULT_ADDITIONAL_QUESTION_RESPONSE_GROUP_VALUES
};

const birthDateSchema = yup.object().shape({
  startDate: yup.date().max(new Date(), '未来の日付は入力できません'),
  endDate: yup
    .date()
    .max(new Date(), '未来の日付は入力できません')
    .min(yup.ref('startDate'), '開始日より前の日付は入力できません')
});

const SdqQuestionResponseSchema = yup.object().shape({
  question_relation: yup.number().required(),
  choice: yup.number()
});

const SdqQuestionResponseGroupSchema = yup.object().shape({
  responses: yup.array().of(SdqQuestionResponseSchema).required()
});

const AdditionalQuestionResponseSchema = yup.object().shape({
  question_relation: yup.number().required(),
  choice: yup.number()
});

const AdditionalQuestionResponseGroupSchema = yup.object().shape({
  responses: yup.array().of(AdditionalQuestionResponseSchema).required()
});

const validationSchema = yup.object().shape({
  last_name: yup.string(),
  first_name: yup.string(),
  birth_date: birthDateSchema,
  sex_of_child: yup.string(),
  signature: yup.string(),
  relationship: yup.string(),
  // もし relationship が「その他」の場合に必須
  relationship_detail: yup.string().when('relationship', {
    is: 'other',
    then: (schema) => schema.required('お子さんとの関係を入力してください'),
    otherwise: (schema) => schema
  }),
  question_response_group: SdqQuestionResponseGroupSchema.required(
    'SDQの回答が作成されません。時間をおいて再度お試しください'
  ),
  additional_question_response_group: AdditionalQuestionResponseGroupSchema.required(
    '追加質問の回答が作成されません。時間をおいて再度お試しください'
  )
});

const title = 'Strength and Difficulties Questionnaire：子どもの強さと困難さアンケート (SDQ)';

export const SdqSurveyPage = () => {
  const [surveyList, setSurveyList] = useState<SurveyItem[]>();
  const [surveyDetail, setSurveyDetail] = useState<Survey>();
  const [respondentChoice, setRespondentChoice] = useState<RespondentChoice>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const {
    setValue,
    // setError,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<RespondentFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_RESPONDENT_VALUES
  });

  const currentFormData = watch();

  /**
   * サーバーサイドから提供される質問データ surveyDetail に基づいて、回答データの初期値を生成する。
   * surveyDetail が変更されたときのみ再計算する。
   */
  const respondentValues = useMemo(() => {
    if (!surveyDetail) return DEFAULT_RESPONDENT_VALUES;
    return {
      ...currentFormData, // 現在の値を保持
      question_response_group: {
        responses: surveyDetail.sdq_question_group.question_relations.map((rel) => {
          return {
            question_relation: rel.id,
            choice: undefined
          };
        })
      },
      additional_question_response_group: {
        responses: surveyDetail.additional_question_group.additional_question_relations.map((rel) => {
          return {
            question_relation: rel.id,
            choice: undefined
          };
        })
      }
    };
  }, [surveyDetail]);

  const fetchSurveyList = async () => {
    try {
      const sl = await getSdqSurveyList();
      setSurveyList(sl);
    } catch (e: any) {
      toast.error('SDQのデータ取得中にエラーが発生しました');
    }
  };

  const fetchRespondentChoices = async () => {
    try {
      const rc = await getSdqRespondentChoices();
      setRespondentChoice(rc);
    } catch (e: any) {
      toast.error('SDQ回答者選択肢のデータ取得中にエラーが発生しました');
    }
  };

  /**
   * ページ表示時にSdqのデータを取得する
   */
  useEffect(() => {
    async function fetchData() {
      await fetchSurveyList();
      await fetchRespondentChoices();
    }

    fetchData();
  }, []);

  /**
   * SDQのデータが取得できたら、フォームフィールドをデフォルトからリセットする
   */
  useEffect(() => {
    reset(respondentValues);
  }, [reset, respondentValues]);

  const onSubmit = async (data: RespondentFormData) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    let postData: RespondentPostData;
    // YYYY-MM-DD の形式に変換
    if (!data.birth_date || data.birth_date.startDate === undefined) {
      postData = {
        ...data,
        birth_date: undefined
      };
    } else {
      postData = {
        ...data,
        birth_date: dayjs(data.birth_date.startDate).format('YYYY-MM-DD')
      };
    }

    try {
      await postSdqSurvey(postData);
      navigate('/submit-success');
    } catch (e: any) {
      // TODO バックエンドのエラーハンドリングを実装する
      console.error('post error: ', e);
    }
    setIsSubmitting(false);
  };

  const onError = () => {
    if (!surveyDetail) return;
    console.log('client errors:', errors);
  };

  // 質問データをフェッチしてformにセットして質問群を表示できるかのフラグ
  const isReady = useMemo(() => {
    return (
      surveyDetail!! &&
      currentFormData.question_response_group.responses.length > 0 &&
      currentFormData.additional_question_response_group.responses.length > 0
    );
  }, [
    currentFormData.additional_question_response_group.responses.length,
    currentFormData.question_response_group.responses.length,
    surveyDetail
  ]);

  return (
    <div className='container mx-auto py-10 flex flex-col items-center gap-6 sm:gap-10'>
      <div className='w-[80%] md:w-full'>
        <Title title={title} />
      </div>
      <div className='md:px-10 px-4 w-full mx-auto'>
        <div className='flex flex-col gap-6 sm:gap-10'>
          {/*select ASQ*/}
          <QuestionBlock>
            <QuestionContainer questionText={'アンケートの種類を選択してください'} required={false}>
              <SelectSdqSurveyField
                surveyList={surveyList}
                setSurveyDetail={setSurveyDetail}
                surveyDetail={surveyDetail}
              />
            </QuestionContainer>
          </QuestionBlock>

          {/*show selected SDQ detail*/}
          {isReady && (
            <>
              <Respondent
                currentFormValues={currentFormData}
                setValue={setValue}
                errors={errors}
                respondentChoice={respondentChoice}
              />
              <SdqQuestionGroupContainer
                sdqQuestionGroup={surveyDetail?.sdq_question_group}
                setValue={setValue}
                currentFormData={currentFormData}
                formErrors={errors}
              />
              <AdditionalQuestionGroupContainer
                additionalQuestionGroup={surveyDetail?.additional_question_group}
                setValue={setValue}
                currentFormData={currentFormData}
                formErrors={errors}
              />
            </>
          )}
          {
            /* errors オブジェクト に中身があったら表示 */
            Object.keys(errors).length > 0 && (
              <div className='mt-2 text-sm text-red'>入力エラーがあります。必須項目を入力してください。</div>
            )
          }
          {surveyDetail && (
            <div className='w-full flex justify-center'>
              <Button title='送信' onClick={handleSubmit(onSubmit, onError)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
