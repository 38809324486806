import { ConcentrationIcon, ParentStatusSessionIcon, ChildSessionIcon } from 'components/Common/Icon';

const constants = {
  DATE_FORMAT: 'YYYY/MM/DD',
  SURVEY_TYPES: [
    // password is required in query params, so this is not should be shown
    // {
    //   type: 'enjoji',
    //   icon: OneSessionIcon,
    //   label: '遠城寺式発達検査'
    // },
    {
      type: 'child_screening',
      icon: ChildSessionIcon,
      label: '子どもの様子に関する観察シート'
    },
    {
      type: 'parents_status',
      icon: ParentStatusSessionIcon,
      label: '子育てに関するアンケート'
    },
    {
      type: 'asq_survey',
      icon: ConcentrationIcon,
      label: 'ASQアンケート'
    },
    {
      type: 'sdq_survey',
      icon: ConcentrationIcon,
      label: 'SDQアンケート'
    }
  ]
};

export default constants;
