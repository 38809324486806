import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useMemo } from 'react';
import { OptionItem } from '../../../../types/components/common/select';
import { Tag } from '../../../Common/Tag';
import { GridContainer } from '../GridContainer';
import { RespondentChoice, RespondentFormData } from '../../../../types/sdq';
import { QuestionBlock } from '../QuestionBlock';
import { QuestionContainer } from '../QuestionContainer';
import { TextInputField } from '../../FormFields/TextInputField';
import { DatepickerField } from '../../FormFields/DatepickerField';
import { RadioGroupField } from '../../FormFields/RadioGroupField';

interface Props {
  currentFormValues: RespondentFormData;
  setValue: UseFormSetValue<RespondentFormData>;
  errors: FieldErrors<RespondentFormData>;
  respondentChoice?: RespondentChoice;
}

export const Respondent = ({ setValue, errors, currentFormValues, respondentChoice }: Props) => {
  const sexOptions: OptionItem[] = useMemo(() => {
    if (!respondentChoice) return [];
    return respondentChoice.sex;
  }, [respondentChoice]);

  const relationshipOptions: OptionItem[] = useMemo(() => {
    if (!respondentChoice) return [];
    return respondentChoice.relationship;
  }, [respondentChoice]);

  return (
    <QuestionBlock className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-8'}>
      <span className='font-semibold sm:text-xl text-lg'>基本情報記入</span>
      <GridContainer>
        <QuestionContainer questionText={'お子さんの姓'}>
          <TextInputField
            value={currentFormValues.last_name}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'last_name'}
            placeholder={'姓'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'お子さんの名'}>
          <TextInputField
            value={currentFormValues.first_name}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'first_name'}
            placeholder={'名'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'お子さんのお誕生日'}>
          <DatepickerField
            value={currentFormValues.birth_date}
            formErrors={errors}
            datepickerProps={{
              i18n: 'ja',
              asSingle: true,
              useRange: false,
              placeholder: '生年月日',
              inputClassName: `w-full bg-white rounded-md border py-2 px-5 text-black outline-none transition disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2`,
              maxDate: new Date(),
              readOnly: true
            }}
            setValue={setValue}
            fieldKey={'birth_date'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'性別'}>
          <RadioGroupField
            setValue={setValue}
            value={currentFormValues.sex_of_child}
            formErrors={errors}
            fieldKey={'sex_of_child'}
            options={sexOptions}
          />
        </QuestionContainer>
      </GridContainer>

      <Tag>記入者の情報</Tag>
      <GridContainer>
        <QuestionContainer questionText={'お子さんとの関係'} required={true}>
          <RadioGroupField
            setValue={setValue}
            value={currentFormValues.relationship}
            formErrors={errors}
            fieldKey={'relationship'}
            options={relationshipOptions}
          />
        </QuestionContainer>

        {currentFormValues.relationship === 'other' && (
          <QuestionContainer questionText={'その他の場合(具体的に)'} required={true}>
            <TextInputField
              value={currentFormValues.relationship_detail}
              setValue={setValue}
              formErrors={errors}
              fieldKey={'relationship_detail'}
              placeholder={'その他の場合'}
            />
          </QuestionContainer>
        )}
        <QuestionContainer questionText={'記入者の署名'}>
          <TextInputField
            value={currentFormValues.signature}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'signature'}
            placeholder={'署名'}
          />
        </QuestionContainer>
      </GridContainer>
    </QuestionBlock>
  );
};
