import configs from '../configs';
import service from './api-helper';
import { QuestionValues, Auth, ChartResult, QuestionGroup, AnswerQuestionGroup, City, School } from '../types';
import {
  SurveyItem as AsqSurveyItem,
  Survey as AsqSurvey,
  RespondentChoice as AsqRespondentChoice,
  RespondentPostData as AsqRespondentPostData
} from '../types/asq';
import {
  SurveyItem as SdqSurveyItem,
  Survey as SdqSurvey,
  RespondentChoice as SdqRespondentChoice,
  RespondentPostData as SdqRespondentPostData
} from '../types/sdq';

export const saveSurvey = (data: QuestionValues) => {
  return service.post(configs.SAVE_SURVEY_URL, data);
};

export const getStatistic = (city_id: string) => {
  return service.get<ChartResult, ChartResult>(configs.GET_STATISTIC_URL, { params: { city_id } });
};

export const getSchools = () => {
  return service.get<School[], School[]>(configs.GET_SCHOOLS_URL, {});
};

export const getCities = () => {
  return service.get<City[], City[]>(configs.GET_CITIES_URL, {});
};

export const getQuestionGroups = (type: string) => {
  return service.get<QuestionGroup[], QuestionGroup[]>(configs.QUESTIONS, { params: { type } });
};

export const saveAnswers = (data: AnswerQuestionGroup[]) => {
  return service.post(configs.QUESTIONS, data);
};

/**
 * only for development
 */
export const getDevOkData = () => {
  return service.get(configs.GET_DEV_URL, {});
};

/**
 * only for development
 */
export const getDevErrorData = () => {
  return service.get(configs.GET_DEV_URL, { params: { hoge: 'hoge' } });
};

export const getAuth = async (): Promise<Auth> => {
  return await service.get<Auth, Auth>(configs.GET_AUTH_URL, {});
};

// ASQ
export const getAsqSurveyList = () => {
  return service.get<AsqSurveyItem[], AsqSurveyItem[]>(configs.GET_ASQ_SURVEY_LIST_URL, {});
};

export const getAsqSurveyDetail = (surveyId: number) => {
  return service.get<AsqSurvey, AsqSurvey>(`${configs.GET_ASQ_SURVEY_DETAIL_URL}${surveyId}`, {});
};

export const getAsqRespondentChoices = () => {
  return service.get<AsqRespondentChoice, AsqRespondentChoice>(configs.GET_ASQ_RESPONDENT_CHOICE_URL, {});
};

export const postAsqSurvey = (data: AsqRespondentPostData) => {
  return service.post<AsqRespondentPostData, AsqRespondentPostData>(configs.POST_ASQ_RESPONDENT_URL, data);
};

// SDQ
export const getSdqSurveyList = () => {
  return service.get<SdqSurveyItem[], SdqSurveyItem[]>(configs.GET_SDQ_SURVEY_LIST_URL, {});
};

export const getSdqSurveyDetail = (surveyId: number) => {
  return service.get<SdqSurvey, SdqSurvey>(`${configs.GET_SDQ_SURVEY_DETAIL_URL}${surveyId}`, {});
};

export const getSdqRespondentChoices = () => {
  return service.get<SdqRespondentChoice, SdqRespondentChoice>(configs.GET_SDQ_RESPONDENT_CHOICE_URL, {});
};

export const postSdqSurvey = (data: SdqRespondentPostData) => {
  return service.post<SdqRespondentPostData, SdqRespondentPostData>(configs.POST_SDQ_RESPONDENT_URL, data);
};
