import { FC, useMemo } from 'react';
import Datepicker, { DatepickerType } from 'react-tailwindcss-datepicker';
import { DatepickerInputValue } from '../../types/components/common/datepicker';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value?: DatepickerInputValue;
  datepickerProps: Omit<DatepickerType, 'value'>;
  label?: string;
  errorMessage?: string;
}

export const DatePicker: FC<Props> = ({ datepickerProps, value, label, errorMessage, ...rest }: Props) => {
  const convertedValue = useMemo(() => {
    return {
      startDate: value?.startDate ?? null,
      endDate: value?.endDate ?? null
    };
  }, [value]);
  return (
    <div {...rest}>
      {label && <label className='mb-2 block text-base font-medium text-black'>{label}</label>}
      <Datepicker value={convertedValue} {...datepickerProps} />
      {errorMessage && <p className='mt-2 text-sm text-red'>{errorMessage}</p>}
    </div>
  );
};
