import Select from '../../Common/Select';
import { useMemo } from 'react';
import { OptionItem } from '../../../types/components/common/select';
import { getSdqSurveyDetail } from '../../../helpers/backend-helper';
import { toast } from 'react-toastify';
import { Survey, SurveyItem } from '../../../types/sdq';

interface Props {
  surveyList?: SurveyItem[];
  setSurveyDetail: React.Dispatch<React.SetStateAction<Survey | undefined>>;
  surveyDetail: Survey | undefined;
}

export const SelectSdqSurveyField = ({ setSurveyDetail, surveyDetail, surveyList }: Props) => {
  const surveyOptions: OptionItem[] = useMemo(() => {
    if (!surveyList) return [];
    return surveyList.map((survey) => {
      return {
        text: survey.readable_title,
        value: survey.id.toString()
      };
    });
  }, [surveyList]);

  const handleOnChange = async (option: OptionItem) => {
    if (!surveyList || surveyList.length < 1) return;

    try {
      const sd = await getSdqSurveyDetail(Number(option.value));
      setSurveyDetail(sd);
    } catch (e: any) {
      toast.error('SDQ詳細のデータ取得中にエラーが発生しました');
    }
  };

  const selectedSurveyId = useMemo(() => {
    if (surveyDetail) {
      return surveyDetail.id.toString();
    }
    return '';
  }, [surveyDetail]);

  return <Select value={selectedSurveyId} onChange={handleOnChange} options={surveyOptions} errorMessage={''} />;
};
