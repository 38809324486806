import { OptionItem } from '../../../../types/components/common/select';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { AdditionalQuestionChoiceGroup, RespondentFormData } from '../../../../types/sdq';
import React, { ComponentProps, useMemo } from 'react';
import { QuestionBlock } from '../QuestionBlock';
import { RadioGroupField } from '../../FormFields/RadioGroupField';

interface Props extends Omit<ComponentProps<typeof QuestionBlock>, 'children'> {
  questionText: string;
  questionIndex: number;
  choices: AdditionalQuestionChoiceGroup;
  setValue: UseFormSetValue<RespondentFormData>;
  nestedFieldKey: string;
  radioValue?: number;
  required?: boolean;
  formErrors: FieldErrors<RespondentFormData>;
}

export const AdditionalQuestionContainer = ({
  questionText,
  questionIndex,
  required,
  setValue,
  choices,
  radioValue,
  nestedFieldKey,
  formErrors,
  ...rest
}: Props) => {
  const currentRadioValue = useMemo(() => {
    return radioValue === null || radioValue === undefined ? '' : radioValue.toString();
  }, [radioValue]);

  const options: OptionItem[] = choices.choices.map((choice) => {
    return {
      value: choice.id.toString(),
      text: choice.text
    };
  });

  return (
    <QuestionBlock {...rest}>
      <div className='flex items-center gap-2 mb-4'>
        <span className='p-2.5 bg-gray-light text-sm sm:text-base font-semibold rounded-lg ring-2 ring-gray-medium'>
          Q{questionIndex}
        </span>
        <span className={`font-semibold sm:text-xl text-lg`}>{questionText}</span>
        {required && <span className='text-red text-sm'>*</span>}
      </div>
      <RadioGroupField
        options={options}
        setValue={setValue}
        value={currentRadioValue}
        fieldKey={'additional_question_response_group'}
        nestedFieldKey={nestedFieldKey}
        formErrors={formErrors}
      />
    </QuestionBlock>
  );
};
