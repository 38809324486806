import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { RespondentFormData, SdqQuestionGroup } from '../../../../types/sdq';
import React, { ComponentProps } from 'react';

import { QuestionBlock } from '../QuestionBlock';
import { SdqQuestionContainer } from './SdqQuestionContainer';

interface Props extends ComponentProps<typeof QuestionBlock> {
  sdqQuestionGroup?: SdqQuestionGroup;
  setValue: UseFormSetValue<RespondentFormData>;
  currentFormData: RespondentFormData;
  formErrors: FieldErrors<RespondentFormData>;
}

export const SdqQuestionGroupContainer = ({
  sdqQuestionGroup,
  currentFormData,
  setValue,
  formErrors,
  ...rest
}: Props) => {
  if (!sdqQuestionGroup) return null;

  return (
    <QuestionBlock className={'flex flex-col gap-8'} {...rest}>
      {sdqQuestionGroup.question_relations.map((relation, questionIndex) => {
        return (
          <SdqQuestionContainer
            key={`sdq-question-${relation.id}`}
            questionText={relation.question.question_text}
            questionIndex={questionIndex + 1}
            options={sdqQuestionGroup.choices}
            setValue={setValue}
            nestedFieldKey={`responses.${questionIndex}.choice`}
            radioValue={currentFormData.question_response_group.responses[questionIndex]?.choice}
            formErrors={formErrors}
            className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-4'}
          />
        );
      })}
    </QuestionBlock>
  );
};
